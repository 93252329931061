import React, { Component } from "react";
import Loading from "../../common/components/loading/loading";
import { withTranslation } from "react-i18next";
import {
  getWindLongTermForecastByMarketDate,
  getSolarLongTermForecastByMarketDate,
  getPoolPriceByMarketDate,
  getDailyPoolPriceAverages,
  getInternalLoadByMarketDate,
  getSummary,
  getDailyOutageReport,
  getMonthlyOutageForecastReport,
} from "../../common/services/marketdata/aesoService";
import {
  getSummaryReport,
  getCalSettlementsByMarketDateRange,
  getSpotSettlementsByMarketDateRange,
} from "../../common/services/marketdata/ngxSettlementService";
import { getRateOfLastResortByMarketDate } from "../../common/services/marketdata/enmaxRateService";
import {
  getTcGasSummaryByMarketDate,
  getTcDailyStorage,
} from "../../common/services/marketdata/tcGasSummaryService";
import { getMarkets } from "../../common/services/markets/marketService";
import AesoSummaryTable from "./aesoSummaryTable";
import AbStorageSummaryTable from "./abStorageSummaryTable";
import HourlyForecastLineGraph from "./hourlyForecastLineGraph";
import DailyForecastBarGraph from "./dailyForecastBarGraph";
import HourlyElectricityPricingAndLoadGraph from "./hourlyElectricityPricingAndLoadGraph";
import DailyElectricityPricingAndLoadGraph from "./dailyElectricityPricingAndLoadGraph";
import DailyElectricityPricingAndLoadDeltaGraph from "./dailyElectricityPricingAndLoadDeltaGraph";
import SettlementsTable from "./settlementsTable";
import HistoricalSettlementsGraph from "./historicalSettlementsGraph";
import MonthlyOutageForecastBarGraph from "./monthlyOutageForecastBarGraph";
import NaturalGasPoolPriceLineGraph from "./naturalGasPoolPriceLineGraph";
import HistoricalHeatRateLineGraph from "./historicalHeatRateLineGraph";
import AesoYearlyAveragePoolPriceGraph from "./aesoYearlyAveragePoolPriceGraph";
import DailyStorageLineGraph from "./dailyStorageLineGraph";
import Input from "../../common/components/form/input";

class AlbertaPowerReport extends Component {
  state = {
    longTermWindForecast: [],
    longTermSolarForecast: [],
    poolPriceInternalLoadDataMerged: [],
    poolPriceDailyAverages: [],
    powerSettles: [],
    gasSettles: [],
    calPowerSettles: [],
    calGasSettles: [],
    spotGasSettles: [],
    poolPrices: [],
    internalLoad: [],
    monthlyOutageForecast: [],
    abDailyStorage: [],
    marketDate: "",
    isLoading: true,
    reloading: false,
  };

  async componentDidMount() {
    const marketDate = new Date().toLocaleDateString("fr-CA");

    await this.loadData(marketDate);
  }

  async loadData(marketDate) {
    var fromDate = new Date(marketDate);
    fromDate.setDate(fromDate.getDate() - 30);

    var toDate = new Date(marketDate);
    toDate.setDate(toDate.getDate() - 0);

    const markets = await getMarkets();
    const aesoMarket = markets.find((x) => x.name === "AESO");
    const albertaGasMarket = markets.find((x) => x.name === "AECO-C");

    const fromDateParam = fromDate.toLocaleDateString("fr-CA", {
      timeZone: "UTC",
    });
    const toDateParam = toDate.toLocaleDateString("fr-CA", { timeZone: "UTC" });

    //set Prior day param
    const priorDay = new Date(marketDate);
    priorDay.setDate(priorDay.getDate() - 1);

    //set Prior year param
    const priorYear = new Date(priorDay);
    priorYear.setFullYear(priorYear.getFullYear() - 1);

    //Set start of 5 years ago param
    const fiveYearAgoStart = new Date(
      new Date(marketDate).getFullYear() - 5,
      0,
      1
    );

    const [
      longTermWindForecast,
      longTermSolarForecast,
      aesoSummaryCurrent,
      aesoSummaryPriorYear,
      poolPrices,
      internalLoad,
      monthlyOutageForecast,
      poolPriceDailyAverages,
      abDailyStorage,
    ] = await Promise.all([
      getWindLongTermForecastByMarketDate(marketDate),
      getSolarLongTermForecastByMarketDate(marketDate),
      getSummary(priorDay.toLocaleDateString("fr-CA", { timeZone: "UTC" })),
      getSummary(priorYear.toLocaleDateString("fr-CA", { timeZone: "UTC" })),
      getPoolPriceByMarketDate(fromDateParam, toDateParam),
      getInternalLoadByMarketDate(fromDateParam, toDateParam),
      getMonthlyOutageForecastReport(marketDate),
      getDailyPoolPriceAverages(marketDate),
      getTcDailyStorage(
        fiveYearAgoStart.toLocaleDateString("fr-CA", { timeZone: "UTC" }),
        marketDate
      ),
    ]);

    const poolPriceInternalLoadDataMerged =
      await this.mergePoolPriceInternalLoad(poolPrices, internalLoad);

    const aesoSummaryData = await this.generateAesoSummary(
      aesoSummaryCurrent,
      aesoSummaryPriorYear,
      longTermWindForecast,
      longTermSolarForecast,
      marketDate
    );

    this.setState({
      longTermWindForecast: longTermWindForecast,
      longTermSolarForecast: longTermSolarForecast,
      poolPrices: poolPrices,
      poolPriceDailyAverages: poolPriceDailyAverages,
      internalLoad: internalLoad,
      aesoSummaryData: aesoSummaryData,
      poolPriceInternalLoadDataMerged: poolPriceInternalLoadDataMerged,
      monthlyOutageForecast: monthlyOutageForecast,
      abDailyStorage: abDailyStorage,
      isLoading: false,
      reloading: false,
      marketDate: marketDate,
    });

    //Set Cal Settles Paramters
    const calSettlesFromDate = new Date(marketDate);
    calSettlesFromDate.setFullYear(calSettlesFromDate.getFullYear() - 3);

    const fromTerm = new Date(marketDate).getFullYear();
    const toTerm = fromTerm + 4;

    //Force these longer running queries to run in parallel.
    const [
      powerSettlesRaw,
      gasSettlesRaw,
      calPowerSettlesRaw,
      calGasSettlesRaw,
      spotGasSettles,
    ] = await Promise.all([
      getSummaryReport(aesoMarket.marketId, marketDate),
      getSummaryReport(albertaGasMarket.marketId, marketDate),
      getCalSettlementsByMarketDateRange(
        aesoMarket.marketId,
        calSettlesFromDate.toLocaleDateString("fr-CA"),
        marketDate
      ),
      getCalSettlementsByMarketDateRange(
        albertaGasMarket.marketId,
        calSettlesFromDate.toLocaleDateString("fr-CA"),
        marketDate
      ),
      getSpotSettlementsByMarketDateRange(
        albertaGasMarket.marketId,
        new Date("2017-01-01").toLocaleDateString("fr-CA"), //Hard code this for now
        marketDate
      ),
    ]);

    const powerSettles = await this.sortAndSliceSettles(powerSettlesRaw);
    const gasSettles = await this.sortAndSliceSettles(gasSettlesRaw);

    const calPowerSettles = calPowerSettlesRaw.filter(
      (x) => x.termName >= "Cal " + fromTerm && x.termName <= "Cal " + toTerm
    );

    const calGasSettles = calGasSettlesRaw.filter(
      (x) => x.termName >= "Cal " + fromTerm && x.termName <= "Cal " + toTerm
    );

    this.setState({
      powerSettles: powerSettles,
      gasSettles: gasSettles,
      calPowerSettles: calPowerSettles,
      calGasSettles: calGasSettles,
      spotGasSettles: spotGasSettles,
    });
  }

  async sortAndSliceSettles(settles) {
    const calSettles = settles.filter((x) => x.termName.indexOf("Cal") >= 0);
    calSettles.sort((a, b) => a.termName - b.termName);

    const monthlySettles = settles.filter(
      (x) =>
        x.termName.indexOf("Cal") < 0 &&
        x.termName !== "Prior Day" &&
        x.termName !== "Same Day" &&
        x.termName !== "Day Ahead" &&
        x.termName.indexOf("D+") < 0
    );
    monthlySettles.sort(
      (a, b) => new Date(a.beginDate) - new Date(b.beginDate)
    );

    const dailySettles = settles.filter(
      (x) =>
        x.termName === "Prior Day" ||
        x.termName === "Same Day" ||
        x.termName === "Day Ahead" /*  || x.termName.indexOf('D+') >= 0 */
    );
    dailySettles.sort((a, b) => new Date(a.beginDate) - new Date(b.beginDate));

    const processedSettles = [
      ...dailySettles,
      ...monthlySettles.slice(0, 12),
      ...calSettles.slice(0, 5),
    ];

    return processedSettles;
  }

  async mergePoolPriceInternalLoad(poolPrices, internalLoad) {
    function sameDay(date1, date2) {
      const d1 = new Date(date1);
      const d2 = new Date(date2);
      return (
        d1.getDate() === d2.getDate() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getFullYear() === d2.getFullYear()
      );
    }

    // Merge the two datasets based on begin_datetime_mpt
    const poolPriceInternalLoadDataMerged = poolPrices.map((poolItem) => {
      const matchingInternalLoadItem = internalLoad.find(
        (internalItem) =>
          sameDay(internalItem.marketDate, poolItem.marketDate) &&
          internalItem.beginDateTimeMpt === poolItem.beginDateTimeMpt
      );
      return {
        beginDateTimeMpt: poolItem.beginDateTimeMpt,
        beginDateTimeUtc: poolItem.beginDateTimeUtc,
        albertaInternalLoad: matchingInternalLoadItem
          ? matchingInternalLoadItem.albertaInternalLoad
          : null,
        forecastAlbertaInternalLoad: matchingInternalLoadItem
          ? matchingInternalLoadItem.forecastAlbertaInternalLoad
          : null,
        poolPrice: poolItem.poolPrice,
        forecastPoolPrice: poolItem.forecastPoolPrice,
        rolling30DayAvg: poolItem.rolling30DayAvg,
        marketDate: poolItem.marketDate,
      };
    });

    return poolPriceInternalLoadDataMerged;
  }

  async generateAesoSummary(
    aesoSummaryCurrent,
    aesoSummaryPriorYear,
    longTermWindForecast,
    longTermSolarForecast,
    marketDate
  ) {
    const aesoSummaryCurrentExtended = aesoSummaryCurrent.map((data) => ({
      sortOrder:
        data.item === "Summary"
          ? 0
          : data.item === "MTD Avg"
          ? 1
          : data.item === "YTD Avg"
          ? 2
          : 999,
      ...data,
      pricePrecision: 2,
      currencySymbol: "$",
      uomSymbol: "",
    }));

    const aesoLoadExtended = aesoSummaryCurrent
      .filter((x) => x.item === "Summary")
      .map((data) => ({
        sortOrder: data.item === "Summary" ? 3 : 999,
        item: "Avg Load",
        beginDateTime: data.beginDateTime,
        numericAverage: data.averageLoad,
        numericAverageYesterday: data.averageLoadYesterday,
        onPeak7x16Average: data.onPeak7x16AverageLoad,
        onPeak7x16AverageYesterday: data.onPeak7x16AverageLoadYesterday,
        offPeak7x16Average: data.offPeak7x16AverageLoad,
        offPeak7x16AverageYesterday: data.offPeak7x16AverageLoadYesterday,
        pricePrecision: 0,
        currencySymbol: "",
        uomSymbol: "MW",
      }));

    const aesoSummaryPriorYearExtended = aesoSummaryPriorYear
      .filter((x) => x.item !== "MTD Avg")
      .map((data) => ({
        sortOrder:
          data.item === "MTD Avg" ? 4 : data.item === "YTD Avg" ? 5 : 999,
        ...data,
        item:
          data.item === "Summary"
            ? "Prior Year"
            : data.item === "YTD Avg"
            ? "YTD Prior Year"
            : "Unknown",
        pricePrecision: 2,
        currencySymbol: "$",
        uomSymbol: "",
      }));

    const currentDate = new Date(marketDate);
    currentDate.setDate(currentDate.getDate() - 1);
    currentDate.setMinutes(
      currentDate.getMinutes() + currentDate.getTimezoneOffset()
    );

    const priorDate = new Date(currentDate);
    priorDate.setDate(priorDate.getDate() - 1);
    priorDate.setMinutes(
      priorDate.getMinutes() + priorDate.getTimezoneOffset()
    );

    const windToday = longTermWindForecast.filter(
      (x) =>
        new Date(x.forecastTransactionDate).toLocaleDateString("fr-CA", {
          timeZone: "UTC",
        }) === currentDate.toLocaleDateString("fr-CA")
    );
    const windTodayOnPeak = windToday.filter(
      (x) =>
        new Date(x.forecastTransactionDate).getHours() >= 8 &&
        new Date(x.forecastTransactionDate).getHours() <= 22
    );
    const windTodayOffPeak = windToday.filter(
      (x) =>
        new Date(x.forecastTransactionDate).getHours() < 8 ||
        new Date(x.forecastTransactionDate).getHours() > 22
    );

    const windYesterday = longTermWindForecast.filter(
      (x) =>
        new Date(x.forecastTransactionDate).toLocaleDateString("fr-CA", {
          timeZone: "UTC",
        }) === priorDate.toLocaleDateString("fr-CA")
    );
    const windYesterdayOnPeak = windYesterday.filter(
      (x) =>
        new Date(x.forecastTransactionDate).getHours() >= 8 &&
        new Date(x.forecastTransactionDate).getHours() <= 22
    );
    const windYesterdayOffPeak = windYesterday.filter(
      (x) =>
        new Date(x.forecastTransactionDate).getHours() < 8 ||
        new Date(x.forecastTransactionDate).getHours() > 22
    );

    const wind = [
      {
        sortOrder: 6,
        item: "Wind",
        beginDateTime: currentDate,
        numericAverage:
          windToday.length > 0
            ? windToday.reduce((a, b) => ({ actual: a.actual + b.actual }))
                .actual / windToday.length
            : null,
        numericAverageYesterday:
          windYesterday.length > 0
            ? windYesterday.reduce((a, b) => ({ actual: a.actual + b.actual }))
                .actual / windToday.length
            : null,
        onPeak7x16Average:
          windTodayOnPeak.length > 0
            ? windTodayOnPeak.reduce((a, b) => ({
                actual: a.actual + b.actual,
              })).actual / windTodayOnPeak.length
            : null,
        onPeak7x16AverageYesterday:
          windYesterdayOnPeak.length > 0
            ? windYesterdayOnPeak.reduce((a, b) => ({
                actual: a.actual + b.actual,
              })).actual / windYesterdayOnPeak.length
            : null,
        offPeak7x16Average:
          windTodayOffPeak.length > 0
            ? windTodayOffPeak.reduce((a, b) => ({
                actual: a.actual + b.actual,
              })).actual / windTodayOffPeak.length
            : null,
        offPeak7x16AverageYesterday:
          windYesterdayOffPeak.length > 0
            ? windYesterdayOffPeak.reduce((a, b) => ({
                actual: a.actual + b.actual,
              })).actual / windYesterdayOffPeak.length
            : null,
        pricePrecision: 0,
        currencySymbol: "",
        uomSymbol: "MW",
      },
    ];

    const solarToday = longTermSolarForecast.filter(
      (x) =>
        new Date(x.forecastTransactionDate).toLocaleDateString("fr-CA", {
          timeZone: "UTC",
        }) === currentDate.toLocaleDateString("fr-CA")
    );
    const solarTodayOnPeak = solarToday.filter(
      (x) =>
        new Date(x.forecastTransactionDate).getHours() >= 8 &&
        new Date(x.forecastTransactionDate).getHours() <= 22
    );
    const solarTodayOffPeak = solarToday.filter(
      (x) =>
        new Date(x.forecastTransactionDate).getHours() < 8 ||
        new Date(x.forecastTransactionDate).getHours() > 22
    );

    const solarYesterday = longTermSolarForecast.filter(
      (x) =>
        new Date(x.forecastTransactionDate).toLocaleDateString("fr-CA", {
          timeZone: "UTC",
        }) === priorDate.toLocaleDateString("fr-CA")
    );
    const solarYesterdayOnPeak = solarYesterday.filter(
      (x) =>
        new Date(x.forecastTransactionDate).getHours() >= 8 &&
        new Date(x.forecastTransactionDate).getHours() <= 22
    );
    const solarYesterdayOffPeak = solarYesterday.filter(
      (x) =>
        new Date(x.forecastTransactionDate).getHours() < 8 ||
        new Date(x.forecastTransactionDate).getHours() > 22
    );

    const solar = [
      {
        sortOrder: 7,
        item: "Solar",
        beginDateTime: currentDate,
        numericAverage:
          solarToday.length > 0
            ? solarToday.reduce((a, b) => ({ actual: a.actual + b.actual }))
                .actual / solarToday.length
            : null,
        numericAverageYesterday:
          solarYesterday.length > 0
            ? solarYesterday.reduce((a, b) => ({ actual: a.actual + b.actual }))
                .actual / solarToday.length
            : null,
        onPeak7x16Average:
          solarTodayOnPeak.length > 0
            ? solarTodayOnPeak.reduce((a, b) => ({
                actual: a.actual + b.actual,
              })).actual / solarTodayOnPeak.length
            : null,
        onPeak7x16AverageYesterday:
          solarYesterdayOnPeak.length > 0
            ? solarYesterdayOnPeak.reduce((a, b) => ({
                actual: a.actual + b.actual,
              })).actual / solarYesterdayOnPeak.length
            : null,
        offPeak7x16Average:
          solarTodayOffPeak.length > 0
            ? solarTodayOffPeak.reduce((a, b) => ({
                actual: a.actual + b.actual,
              })).actual / solarTodayOffPeak.length
            : null,
        offPeak7x16AverageYesterday:
          solarYesterdayOffPeak.length > 0
            ? solarYesterdayOffPeak.reduce((a, b) => ({
                actual: a.actual + b.actual,
              })).actual / solarYesterdayOffPeak.length
            : null,
        pricePrecision: 0,
        currencySymbol: "",
        uomSymbol: "MW",
      },
    ];

    const aesoSummary = [
      ...aesoSummaryCurrentExtended,
      ...aesoLoadExtended,
      ...aesoSummaryPriorYearExtended,
      ...wind,
      ...solar,
    ];

    return aesoSummary;
  }

  handleDateChange = (marketDate) => {
    this.setState({ marketDate: marketDate, reloading: true });

    (async () => {
      await this.loadData(marketDate);
    })();
  };

  render() {
    const {
      longTermWindForecast,
      longTermSolarForecast,
      powerSettles,
      gasSettles,
      calPowerSettles,
      calGasSettles,
      spotGasSettles,
      poolPriceInternalLoadDataMerged,
      poolPriceDailyAverages,
      aesoSummaryData,
      monthlyOutageForecast,
      abDailyStorage,
      isLoading,
      //reloading,
      marketDate,
    } = this.state;

    const { t } = this.props;

    const minDate = "2024-01-01";
    const maxDate = new Date();

    const priorDate = new Date(marketDate);
    priorDate.setDate(priorDate.getDate() - 1);
    priorDate.setMinutes(
      priorDate.getMinutes() + priorDate.getTimezoneOffset()
    );

    const priorWeek = new Date(marketDate);
    priorWeek.setDate(priorWeek.getDate() - 7);
    priorWeek.setMinutes(
      priorWeek.getMinutes() + priorWeek.getTimezoneOffset()
    );

    const priorYear = new Date(marketDate);
    priorYear.setFullYear(priorYear.getFullYear() - 1);

    const priorPriceInternalLoadData = poolPriceInternalLoadDataMerged.filter(
      (x) =>
        new Date(x.beginDateTimeMpt).toLocaleDateString("fr-CA", {
          timeZone: "UTC",
        }) === priorDate.toLocaleDateString("fr-CA")
    );

    const historicalPriceInternalLoadDataMerged =
      poolPriceInternalLoadDataMerged.filter(
        (x) =>
          new Date(x.beginDateTimeMpt).toLocaleDateString("fr-CA", {
            timeZone: "UTC",
          }) <= priorDate.toLocaleDateString("fr-CA")
      );

    const abStorageSummary = abDailyStorage
      .filter(
        (x) =>
          new Date(x.gasDay).toLocaleDateString("fr-CA", {
            timeZone: "UTC",
          }) ===
            new Date(marketDate).toLocaleDateString("fr-CA", {
              timeZone: "UTC",
            }) ||
          new Date(x.gasDay).toLocaleDateString("fr-CA", {
            timeZone: "UTC",
          }) ===
            new Date(priorDate).toLocaleDateString("fr-CA", {
              timeZone: "UTC",
            }) ||
          new Date(x.gasDay).toLocaleDateString("fr-CA", {
            timeZone: "UTC",
          }) ===
            new Date(priorWeek).toLocaleDateString("fr-CA", {
              timeZone: "UTC",
            }) ||
          new Date(x.gasDay).toLocaleDateString("fr-CA", {
            timeZone: "UTC",
          }) ===
            new Date(priorYear).toLocaleDateString("fr-CA", {
              timeZone: "UTC",
            })
      )
      .map((x) => ({
        ...x,
        percentFull: (100 * x.inventory) / x.capacity,
        item:
          new Date(x.gasDay).toLocaleDateString("fr-CA", {
            timeZone: "UTC",
          }) ===
          new Date(marketDate).toLocaleDateString("fr-CA", {
            timeZone: "UTC",
          })
            ? "Current Day"
            : new Date(x.gasDay).toLocaleDateString("fr-CA", {
                timeZone: "UTC",
              }) ===
              new Date(priorDate).toLocaleDateString("fr-CA", {
                timeZone: "UTC",
              })
            ? "Prior Day"
            : new Date(x.gasDay).toLocaleDateString("fr-CA", {
                timeZone: "UTC",
              }) ===
              new Date(priorWeek).toLocaleDateString("fr-CA", {
                timeZone: "UTC",
              })
            ? "Prior Week"
            : new Date(x.gasDay).toLocaleDateString("fr-CA", {
                timeZone: "UTC",
              }) ===
              new Date(priorYear).toLocaleDateString("fr-CA", {
                timeZone: "UTC",
              })
            ? "Prior Year"
            : "",
      }));

    abStorageSummary.sort((a, b) => new Date(b.gasDay) - new Date(a.gasDay));

    if (isLoading) return <Loading />;

    return (
      <div className="container-fluid" style={{ height: "100%" }}>
        <div className="row" style={{ height: "5%" }}>
          <div className="col-md-12 d-flex align-items-center justify-content-center">
            <h1>{t("AlbertaEnergyDaily")}</h1>
          </div>
        </div>
        <div className="row" style={{ height: "8%" }}>
          <div className="col-md-2">
            <Input
              type="date"
              min={minDate}
              max={maxDate}
              onKeyDown={(e) => e.preventDefault()}
              // onFocus={(e) => e.blur()}
              onChange={(e) => this.handleDateChange(e.currentTarget.value)}
              value={marketDate}
              label={t("MarketDate")}
            />
          </div>
        </div>
        <div
          className="row"
          style={{ overflowY: "auto", overflowX: "hidden", height: "87%" }}
        >
          <div className="col">
            <div className="row">
              <div className="col-md-8 tile">
                AESO Summary
                <AesoSummaryTable
                  summaryData={aesoSummaryData}
                  currencySymbol={"$"}
                  pricePrecision={2}
                />
              </div>
              <div className="col-md-4 tile">
                AB Natural Gas Storage (Bcf)
                <AbStorageSummaryTable summaryData={abStorageSummary} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 tile" style={{ minHeight: "300px" }}>
                AESO Generation Statuses
              </div>
              <div className="col-md-8 tile" style={{ minHeight: "300px" }}>
                Alberta Retail Pricing Comparison
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 tile" style={{ height: "300px" }}>
                {/*AESO Average 7X24 Price and Load Last 30 days*/}
                <DailyElectricityPricingAndLoadGraph
                  data={historicalPriceInternalLoadDataMerged}
                />
              </div>
              <div className="col-md-4 tile" style={{ height: "300px" }}>
                {/*AESO Average 7X24 Price and Load Delta Last 30 days*/}
                <DailyElectricityPricingAndLoadDeltaGraph
                  data={historicalPriceInternalLoadDataMerged}
                />
              </div>
              <div className="col-md-4 tile" style={{ height: "300px" }}>
                {/*AESO Average Hourly Electricity Pricing and Load:{" "}
                {priorDate.toUTCString().substring(0, 17)}*/}
                <HourlyElectricityPricingAndLoadGraph
                  data={priorPriceInternalLoadData}
                  title={
                    "AESO Average Hourly Electricity Pricing and Load: " +
                    priorDate.toUTCString().substring(0, 17)
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 tile" style={{ minHeight: "300px" }}>
                7X24 Alberta Forward Power CAD$/MWh (The Ice)
                <SettlementsTable
                  settlements={powerSettles}
                  currencySymbol={"$"}
                  pricePrecision={2}
                />
              </div>
              <div className="col-md-6 tile" style={{ minHeight: "300px" }}>
                Natural Gas Prices CAD$/GJ (The Ice)
                <SettlementsTable
                  settlements={gasSettles}
                  currencySymbol={"$"}
                  pricePrecision={2}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 tile" style={{ height: "300px" }}>
                {/*Alberta Power Forward Calendar Pricing*/}
                <HistoricalSettlementsGraph
                  data={calPowerSettles}
                  title={"Alberta Power Forward Calendar Pricing"}
                />
              </div>
              <div className="col-md-6 tile" style={{ height: "300px" }}>
                {/*Alberta Forward Natural Gas Calendar Pricing*/}
                <HistoricalSettlementsGraph
                  data={calGasSettles}
                  title={"Alberta Forward Natural Gas Calendar Pricing"}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 tile" style={{ height: "300px" }}>
                {/*Historical AESO 7X24 and Ice Spot Natural Gas*/}
                <NaturalGasPoolPriceLineGraph
                  gasData={spotGasSettles}
                  poolPriceData={poolPriceDailyAverages}
                />
              </div>
              <div className="col-md-6 tile" style={{ height: "300px" }}>
                {/*Historical Heat Rates*/}
                <HistoricalHeatRateLineGraph
                  gasData={spotGasSettles}
                  poolPriceData={poolPriceDailyAverages}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 tile" style={{ height: "300px" }}>
                {/*AESO 7X24 Average Prices 2017 to 2024*/}
                <AesoYearlyAveragePoolPriceGraph
                  poolPriceData={poolPriceDailyAverages}
                />
              </div>
              <div className="col-md-6 tile" style={{ height: "300px" }}>
                AESO Average 7X24 Price and Load
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 tile" style={{ height: "300px" }}>
                {/*Aeso 7 Day Hourly Wind Forecast*/}
                <HourlyForecastLineGraph
                  data={longTermWindForecast}
                  title={"AESO Hourly Wind Forecast"}
                />
              </div>
              <div className="col-md-6 tile" style={{ height: "300px" }}>
                {/*Aeso 7 Day Hourly Solar Forecast*/}
                <HourlyForecastLineGraph
                  data={longTermSolarForecast}
                  title={"AESO Hourly Solar Forecast"}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 tile" style={{ height: "300px" }}>
                {/*Aeso 7 Day Wind Forecast*/}
                <DailyForecastBarGraph
                  data={longTermWindForecast}
                  title={"AESO Daily Wind Forecast"}
                />
              </div>
              <div className="col-md-6 tile" style={{ height: "300px" }}>
                {/*Aeso 7 Day Solar Forecast*/}
                <DailyForecastBarGraph
                  data={longTermSolarForecast}
                  title={"AESO Daily Solar Forecast"}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 tile" style={{ height: "300px" }}>
                RRO Enmax vs AESO 2018 Through 2024
              </div>
              <div className="col-md-6 tile" style={{ height: "300px" }}>
                {/*AESO Monthly Outage Forecast*/}
                <MonthlyOutageForecastBarGraph data={monthlyOutageForecast} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 tile" style={{ height: "300px" }}>
                AESO Historical Monthly
              </div>
              <div className="col-md-6 tile" style={{ height: "300px" }}>
                <DailyStorageLineGraph data={abDailyStorage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation(["marketdata"])(AlbertaPowerReport);
