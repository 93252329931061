import React, { useEffect, useRef, useCallback } from "react";
import * as d3 from "d3";

const DailyElectricityPricingAndLoadGraph = ({ data }) => {
  const svgRef = useRef();

  const renderChart = useCallback(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    // Calculate dimensions based on the size of the parent container
    const parentContainer = svg.node().parentNode;
    const parentWidth = parentContainer.clientWidth;
    const parentHeight = parentContainer.clientHeight;
    const margin = { top: 30, right: 60, bottom: 60, left: 50 };

    // Set dimensions considering margins
    const innerWidth = parentWidth - margin.left - margin.right;
    const innerHeight = parentHeight - margin.top - margin.bottom;

    // Parse date format for `forecastTransactionDate` in UTC to avoid time zone issues
    //const parseDate = d3.utcParse("%Y-%m-%dT%H:%M:%SZ");

    const rollupByDay = (data) => {
      const dailyData = data.reduce((acc, item) => {
        const date = new Date(item.beginDateTimeMpt).toDateString();
        if (!acc[date]) {
          acc[date] = { count: 0, poolPriceSum: 0, albertaInternalLoadSum: 0 };
        }
        acc[date].count++;
        acc[date].poolPriceSum += item.poolPrice;
        acc[date].albertaInternalLoadSum += item.albertaInternalLoad;
        return acc;
      }, {});

      return Object.entries(dailyData).map(([date, values]) => ({
        date: new Date(date),
        poolPrice: values.poolPriceSum / values.count,
        albertaInternalLoad: values.albertaInternalLoadSum / values.count,
      }));
    };

    const formattedData = rollupByDay(data);

    // Create SVG
    const chartSvg = svg
      .append("svg")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("viewBox", `0 0 ${parentWidth} ${parentHeight}`)
      .attr("preserveAspectRatio", "none") // Preserve aspect ratio
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    // Set up scales
    const xScale = d3
      .scaleBand()
      .domain(formattedData.map((d) => d.date))
      .range([0, innerWidth])
      .padding(0.3); // Increased padding for spacing between clusters

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max([...formattedData.map((d) => d.poolPrice)])])
      .nice()
      .range([innerHeight, 0]);

    const yScaleSecondary = d3
      .scaleLinear()
      .domain([
        d3.min([...formattedData.map((d) => d.albertaInternalLoad)]),
        d3.max([...formattedData.map((d) => d.albertaInternalLoad)]),
      ])
      .nice()
      .range([innerHeight, 0]);

    // X and Y Axes
    chartSvg
      .append("g")
      .attr("transform", `translate(0,${innerHeight})`)
      .call(
        d3.axisBottom(xScale).tickFormat(d3.timeFormat("%b %d")) // Format date as "Month Day"
      )
      .selectAll("text")
      .attr("transform", "rotate(-45)")
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em");

    // Format for currency with 2 decimal places
    const formatCurrency = d3.format("$,.2f");

    // Primary Y Axis (Price Delta)
    chartSvg.append("g").call(
      d3.axisLeft(yScale).tickFormat(formatCurrency) // Apply the currency format here
    );

    chartSvg
      .append("g")
      .attr("transform", `translate(${innerWidth},0)`)
      .call(d3.axisRight(yScaleSecondary));

    // Secondary Y Axis label
    chartSvg
      .append("text")
      .attr("transform", "rotate(90)")
      .attr("x", innerHeight / 2)
      .attr("y", -(innerWidth + margin.right - 10))
      .attr("text-anchor", "middle")
      .attr("font-size", "14px")
      .attr("fill", "grey")
      .text("Load (MW)");

    // // Y Axis label
    // chartSvg.append("text")
    // .attr("x", -margin.left + 5)
    // .attr("y", innerHeight / 2)
    // .attr("text-anchor", "middle")
    // .attr("font-size", "14px")
    // .attr("fill", "grey")
    // .text("$");

    // Add gridlines
    chartSvg
      .append("g")
      .selectAll(".grid")
      .data(yScale.ticks())
      .enter()
      .append("line")
      .attr("class", "grid")
      .attr("x1", 0)
      .attr("x2", innerWidth)
      .attr("y1", (d) => yScale(d))
      .attr("y2", (d) => yScale(d))
      .attr("stroke", "grey")
      .attr("opacity", "25%")
      .attr("stroke-width", 1);
    //.attr("stroke-dasharray", "3 3")

    chartSvg
      .selectAll(".bar.albertaInternalLoad")
      .data(formattedData)
      .enter()
      .append("rect")
      .attr("class", "bar albertaInternalLoad")
      .attr("x", (d) => xScale(d.date))
      .attr("y", (d) => yScaleSecondary(d.albertaInternalLoad))
      .attr("width", xScale.bandwidth())
      .attr(
        "height",
        (d) => innerHeight - yScaleSecondary(d.albertaInternalLoad)
      )
      .attr("fill", "#0096FF");

    // Line for Pool Price (dotted blue)
    const linePoolPrice = d3
      .line()
      .x((d) => xScale(d.date))
      .y((d) => yScale(d.poolPrice));

    chartSvg
      .append("path")
      .datum(formattedData)
      .attr("fill", "none")
      .attr("stroke", "red")
      .attr("stroke-width", 2)
      .attr("d", linePoolPrice);

    // Legend
    const legend = chartSvg
      .append("g")
      .attr("transform", `translate(${innerWidth - margin.left - 180}, 0)`);

    // Add title
    chartSvg
      .append("text")
      .attr("x", innerWidth / 2)
      .attr("y", -margin.top / 2)
      .attr("text-anchor", "middle")
      .attr("font-size", "16px")
      .attr("font-weight", "bold")
      .text("AESO Average 7X24 Price and Load")
      .attr("fill", "grey");

    // Legend: Most Likely
    legend
      .append("line")
      .attr("x1", innerWidth / 4 - 60) // Starting point of the line
      .attr("y1", 5) // Position the line vertically
      .attr("x2", innerWidth / 4 + -30) // Ending point of the line (horizontal length)
      .attr("y2", 5) // Same vertical position as the start
      .attr("stroke", "red") // Blue color
      .attr("stroke-width", 2); // Line width

    legend
      .append("text")
      .attr("x", innerWidth / 4 - 25)
      .attr("y", 10)
      .attr("font-size", "12px")
      .attr("fill", "grey")
      .text("Price");

    // Legend: Avg/Day
    legend
      .append("line")
      .attr("x1", innerWidth / 4 + 30) // Starting point of the line
      .attr("y1", 5) // Position the line vertically
      .attr("x2", innerWidth / 4 + 60) // Ending point of the line (horizontal length)
      .attr("y2", 5) // Same vertical position as the start
      .attr("stroke", "#0096FF") // Blue color
      .attr("stroke-width", 2); // Line width

    legend
      .append("text")
      .attr("x", innerWidth / 4 + 65)
      .attr("y", 10)
      .attr("font-size", "12px")
      .attr("fill", "grey")
      .text("Load");
  }, [data]);

  useEffect(() => {
    renderChart();

    return () => {
      /*         // Remove the tooltip when the component unmounts
        if (tooltipRef.current) {
            tooltipRef.current.remove();
        } */
    };
  }, [renderChart]);

  useEffect(() => {
    const innerChartRef = svgRef;

    const handleResize = () => {
      renderChart(); // Re-render the chart on resize
    };

    const observer = new ResizeObserver(handleResize);
    if (innerChartRef.current) {
      observer.observe(innerChartRef.current);
    }

    // Clean up observer on component unmount
    return () => {
      if (innerChartRef.current) {
        observer.unobserve(innerChartRef.current);
      }
    };
  }, [renderChart]);

  return <svg ref={svgRef} width="100%" height="100%"></svg>;
};

export default DailyElectricityPricingAndLoadGraph;
