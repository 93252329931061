import { useState, React } from "react";
import { NavLink, useMatch } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import FontAwesome from "react-fontawesome";
import { useKeycloak } from "@react-keycloak/web";
import Logo from "../../config/images/Neutral Markets Logo - Light.png";
import { useTranslation } from "react-i18next";
import { usePermitted } from "../../common/components/permissions/permissions";
import Modal from "react-bootstrap/Modal";
import Badge from "react-bootstrap/Badge";
import {
  openMarkets,
  closeMarkets,
} from "../../common/services/tradingEngine/marketStateService";
import { toast } from "react-toastify";

const NavHeader = (props) => {
  const { keycloak, initialized } = useKeycloak();
  const { t } = useTranslation();
  const [showAboutModal, setShowAboutModal] = useState(false);

  const version = process.env.REACT_APP_VERSION;
  const enableTrading = process.env.REACT_APP_ENABLE_TRADING === "true";

  const authenticated = initialized && keycloak.authenticated;
  const login =
    keycloak.authenticated && keycloak.tokenParsed.preferred_username;
  const showAdmin = usePermitted(["administrator", "operator"]);
  const showUserAdmin = usePermitted(["administrator", "operator"]);
  const showProductAdmin = usePermitted(["administrator", "operator"]);
  const showMarketAdmin = usePermitted(["administrator", "operator"]);
  //const showMarketData = usePermitted(["administrator","operator","market-data-provider","market-data-subscriber"]);
  const showMarketData = true; //We want these menu items to always render and show the unauthorized page if the user doesn't have access.
  const showSubmitMarks = usePermitted(["api-manage-marks"]);
  const showSubmitTrades = usePermitted(["api-manage-trades"]);
  const showSubmitExternalData = usePermitted(["api-manage-external-data"]);
  const showReviewMarks = usePermitted(["api-approve-marks"]);
  const showReviewTrades = usePermitted(["api-approve-trades"]);
  //const showSubmitterDashboard = usePermitted(["administrator","operator","market-data-provider"]);
  const showTrading = usePermitted(["administrator"]); //temporary for now
  const showTradingAdmin = usePermitted(["administrator"]); //temporary for now
  const tradingRoute = useMatch("/trading");
  const showMarketControl = showTradingAdmin && tradingRoute;
  const showEnergyDaily = usePermitted([
    "administrator",
    "operator",
    "energy-daily-subscriber",
  ]); //temporary for now

  const handleCloseAboutModel = () => setShowAboutModal(false);
  const handleShowAboutModel = () => setShowAboutModal(true);

  const handleOpenMarkets = async () => {
    try {
      await openMarkets();
      toast.success(t("MarketsOpened"));
    } catch (ex) {
      console.log(t("ErrorSaving"), ex);

      if (ex.response && ex.response.status === 400) {
        var errorList = ex.response.data.errors;
        if (errorList) {
          var errorKeys = Object.keys(ex.response.data.errors);
          errorKeys.forEach(function (key) {
            errorList[key].map((error) => {
              toast.error(t("ErrorSaving") + " " + key + ": " + error);
              return null;
            });
          });
        } else {
          toast.error(t("ErrorSaving") + " " + ex.response.data);
        }
      }
    }
  };

  const handleCloseMarkets = async () => {
    try {
      await closeMarkets();
      toast.success(t("MarketsClosed"));
    } catch (ex) {
      console.log(t("ErrorSaving"), ex);

      if (ex.response && ex.response.status === 400) {
        var errorList = ex.response.data.errors;
        if (errorList) {
          var errorKeys = Object.keys(ex.response.data.errors);
          errorKeys.forEach(function (key) {
            errorList[key].map((error) => {
              toast.error(t("ErrorSaving") + " " + key + ": " + error);
              return null;
            });
          });
        } else {
          toast.error(t("ErrorSaving") + " " + ex.response.data);
        }
      }
    }
  };

  return (
    <div>
      <Navbar expand="lg" className={props.navTheme}>
        <Container>
          <Navbar.Brand href="http://www.neutralmarkets.com/" target="_blank">
            <img src={Logo} alt="Neutral Markets" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavLink id="home-button" className="nav-item nav-link" to="/">
                {t("Home")}
              </NavLink>
              {authenticated && (
                <NavDropdown
                  title={t("MarketData")}
                  id="market-data-dropdown-menu"
                >
                  {showMarketData && (
                    <NavDropdown.Item as={NavLink} to="/marketdata/">
                      <FontAwesome
                        className="nav-menu-icon "
                        name="chart-column"
                      />{" "}
                      {t("DailySummary")}
                    </NavDropdown.Item>
                  )}
                  {showMarketData && (
                    <NavDropdown.Item as={NavLink} to="/historicalmarketdata/">
                      <FontAwesome
                        className="nav-menu-icon "
                        name="chart-line"
                      />{" "}
                      {t("HistoricalMarketData")}
                    </NavDropdown.Item>
                  )}
                  {showMarketData && (
                    <NavDropdown.Item as={NavLink} to="/historicaltrades/">
                      <FontAwesome
                        className="nav-menu-icon "
                        name="arrow-right-arrow-left"
                      />{" "}
                      {t("HistoricalTrades")}
                    </NavDropdown.Item>
                  )}
                  {(showSubmitMarks || showSubmitTrades) && (
                    <NavDropdown.Divider />
                  )}
                  {showSubmitMarks && (
                    <NavDropdown.Item as={NavLink} to="/marketdata/submitmarks">
                      <FontAwesome
                        className="nav-menu-icon"
                        name="comment-dollar"
                      />{" "}
                      {t("MarketDataSubmitMarks")}
                    </NavDropdown.Item>
                  )}
                  {showSubmitTrades && (
                    <NavDropdown.Item
                      as={NavLink}
                      to="/marketdata/submittrades"
                    >
                      <FontAwesome
                        className="nav-menu-icon"
                        name="comments-dollar"
                      />{" "}
                      {t("MarketDataSubmitTrades")}
                    </NavDropdown.Item>
                  )}
                  {(showReviewMarks || showReviewTrades) && (
                    <NavDropdown.Divider />
                  )}
                  {showReviewMarks && (
                    <NavDropdown.Item as={NavLink} to="/marketdata/reviewmarks">
                      <FontAwesome
                        className="nav-menu-icon"
                        name="magnifying-glass-dollar"
                      />{" "}
                      {t("MarketDataReviewMarks")}{" "}
                      {props.unapprovedMarkCount > 0 && (
                        <Badge pill bg="warning" text="dark">
                          {props.unapprovedMarkCount}
                        </Badge>
                      )}
                    </NavDropdown.Item>
                  )}
                  {showReviewTrades && (
                    <NavDropdown.Item
                      as={NavLink}
                      to="/marketdata/reviewtrades"
                    >
                      <FontAwesome
                        className="nav-menu-icon"
                        name="magnifying-glass-arrow-right"
                      />{" "}
                      {t("MarketDataReviewTrades")}{" "}
                      {props.unapprovedTradeCount > 0 && (
                        <Badge pill bg="warning" text="dark">
                          {props.unapprovedTradeCount}
                        </Badge>
                      )}
                    </NavDropdown.Item>
                  )}
                  {/*{(showSubmitterDashboard) && 
                    <NavDropdown.Divider />
                  }
                  {showSubmitterDashboard && 
                    <NavDropdown.Item as={NavLink} to="/marketdata/submitterdashboard">
                      <FontAwesome className="nav-menu-icon" name="chart-pie" />{" "}
                      {t("MarketDataSubmitterDashboard")}
                    </NavDropdown.Item>
                  } */}
                </NavDropdown>
              )}
              {authenticated && (
                <NavDropdown title={t("Tools")} id="market-data-dropdown-menu">
                  {showEnergyDaily && (
                    <NavDropdown.Item as={NavLink} to="/albertaenergydaily">
                      <FontAwesome
                        className="nav-menu-icon "
                        name="plug-circle-bolt"
                      />{" "}
                      {t("AlbertaEnergyDaily")}
                    </NavDropdown.Item>
                  )}
                  {/* Free tools, no restrictions */}
                  <NavDropdown
                    title={
                      <span style={{ paddingLeft: "8px" }}>
                        <FontAwesome className="nav-menu-icon" name="leaf" />{" "}
                        <span>{t("AlbertaEnvironmental")}</span>
                      </span>
                    }
                    id="dashboards-dropdown"
                    className="dropend"
                  >
                    <NavDropdown.Item
                      as={NavLink}
                      to="/dashboards/albertaenvironmental"
                    >
                      <FontAwesome className="nav-menu-icon " name="leaf" />{" "}
                      {t("AlbertaEnvironmentalDashboard")}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/dashboards/albertaenvironmental/trades"
                    >
                      <FontAwesome
                        className="nav-menu-icon "
                        name="arrow-right-arrow-left"
                      />{" "}
                      {t("AlbertaEnvironmentalTrades")}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/dashboards/albertaenvironmental/registry"
                    >
                      <FontAwesome className="nav-menu-icon " name="book" />{" "}
                      {t("AlbertaEnvironmentalRegistry")}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/dashboards/albertaenvironmental/issues"
                    >
                      <FontAwesome className="nav-menu-icon " name="seedling" />{" "}
                      {t("AlbertaEnvironmentalIssues")}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/dashboards/albertaenvironmental/statuschanges"
                    >
                      <FontAwesome
                        className="nav-menu-icon "
                        name="arrows-turn-to-dots"
                      />{" "}
                      {t("AlbertaEnvironmentalStatusChanges")}
                    </NavDropdown.Item>
                  </NavDropdown>

                  {showMarketData && (
                    <NavDropdown
                      title={
                        <span style={{ paddingLeft: "8px" }}>
                          <FontAwesome className="nav-menu-icon" name="bolt" />{" "}
                          <span>{t("AlbertaElectricity")}</span>
                        </span>
                      }
                      id="tools-dropdown"
                      className="dropend"
                    >
                      <NavDropdown.Item
                        as={NavLink}
                        to="/dashboards/albertaelectricity"
                      >
                        <FontAwesome className="nav-menu-icon " name="bolt" />{" "}
                        {t("AlbertaElectricityDashboard")}
                      </NavDropdown.Item>
                    </NavDropdown>
                  )}

                  {showSubmitExternalData && <NavDropdown.Divider />}
                  {showSubmitExternalData && (
                    <NavDropdown.Item
                      as={NavLink}
                      to="/marketdata/submitexternaldata"
                    >
                      <FontAwesome
                        className="nav-menu-icon"
                        name="comments-dollar"
                      />{" "}
                      {t("MarketDataSubmitExternalData")}
                    </NavDropdown.Item>
                  )}
                </NavDropdown>
              )}
              {enableTrading && showTrading && (
                <NavLink className="nav-item nav-link" to="/trading">
                  {t("Trading")}
                </NavLink>
              )}
            </Nav>
            <Nav>
              {authenticated && showAdmin && (
                <NavDropdown
                  title={t("Admin")}
                  id="basic-nav-dropdown"
                  align="end"
                >
                  {showUserAdmin && (
                    <NavDropdown.Item as={NavLink} to="/admin/auth">
                      <FontAwesome className="nav-menu-icon" name="users" />{" "}
                      {t("UserAdmin")}
                    </NavDropdown.Item>
                  )}
                  {showProductAdmin && (
                    <NavDropdown.Item as={NavLink} to="/admin/products">
                      <FontAwesome className="nav-menu-icon" name="coins" />{" "}
                      {t("ProductAdmin")}
                    </NavDropdown.Item>
                  )}
                  {showMarketAdmin && (
                    <NavDropdown.Item as={NavLink} to="/admin/markets">
                      <FontAwesome className="nav-menu-icon" name="store" />{" "}
                      {t("MarketAdmin")}
                    </NavDropdown.Item>
                  )}
                  {showTradingAdmin && (
                    <NavDropdown.Item as={NavLink} to="/admin/trading">
                      <FontAwesome className="nav-menu-icon" name="gears" />{" "}
                      {t("TradingAdmin")}
                    </NavDropdown.Item>
                  )}
                  {showMarketControl && (
                    // <NavDropdown.Item>
                    //   <FontAwesome className="nav-menu-icon" name="toggle-on" />{" "}
                    //   {t("MarketControl")}
                    // </NavDropdown.Item>

                    <NavDropdown
                      title={
                        <span style={{ paddingLeft: "8px" }}>
                          <FontAwesome
                            className="nav-menu-icon"
                            name="toggle-on"
                          />{" "}
                          <span>{t("MarketControl")}</span>
                        </span>
                      }
                      id="market-control-dropdown"
                      className="dropstart"
                    >
                      <NavDropdown.Item onClick={handleOpenMarkets}>
                        <FontAwesome
                          className="nav-menu-icon "
                          name="toggle-on"
                        />{" "}
                        {t("OpenMarkets")}
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={handleCloseMarkets}>
                        <FontAwesome
                          className="nav-menu-icon "
                          name="toggle-off"
                        />{" "}
                        {t("CloseMarkets")}
                      </NavDropdown.Item>
                    </NavDropdown>
                  )}
                </NavDropdown>
              )}
              {authenticated && (
                <NavDropdown title={login} id="basic-nav-dropdown" align="end">
                  <NavDropdown.Item as={NavLink} to="/profile">
                    <FontAwesome className="nav-menu-icon" name="user" />{" "}
                    {t("UserProfile")}
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={handleShowAboutModel}>
                    <FontAwesome className="nav-menu-icon" name="info-circle" />{" "}
                    {t("About")}
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={() => keycloak.logout()}>
                    <FontAwesome className="nav-menu-icon" name="sign-out" />{" "}
                    {t("Logout")}
                  </NavDropdown.Item>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Modal
        show={showAboutModal}
        size="md"
        onHide={handleCloseAboutModel}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>© Neutral Markets 2025</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("Version")}
          {": "}
          {version}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NavHeader;
