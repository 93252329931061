import React, { useEffect, useRef, useCallback } from "react";
import * as d3 from "d3";

const AesoYearlyAveragePoolPriceGraph = ({ poolPriceData }) => {
  const svgRef = useRef();

  const renderChart = useCallback(() => {
    if (!poolPriceData || poolPriceData.length === 0) {
      console.error("No data available for rendering");
      return;
    }

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    // Calculate dimensions based on the size of the parent container
    const parentContainer = svg.node().parentNode;
    const parentWidth = parentContainer.clientWidth;
    const parentHeight = parentContainer.clientHeight;
    const margin = { top: 40, right: 30, bottom: 40, left: 70 };

    // Set dimensions considering margins
    const innerWidth = parentWidth - margin.left - margin.right;
    const innerHeight = parentHeight - margin.top - margin.bottom;

    // Create SVG
    const chartSvg = svg
      .attr("width", parentWidth)
      .attr("height", parentHeight)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    // Parse dates and average by year
    const parseDate = d3.timeParse("%Y-%m-%dT%H:%M:%SZ");
    const formatYear = d3.timeFormat("%Y");

    const averageByYear = (data) => {
      const groupedData = d3.group(data, (d) => {
        const parsedDate = parseDate(d.marketDate);
        if (!parsedDate) {
          //console.error(`Failed to parse date: ${d.marketDate}`);
          return null;
        }
        return formatYear(parsedDate);
      });

      return Array.from(groupedData, ([key, values]) => ({
        year: key,
        avgPoolPrice: d3.mean(values, (d) => +d.poolPrice),
      })).sort((a, b) => d3.ascending(a.year, b.year));
    };

    const yearlyData = averageByYear(poolPriceData);

    if (yearlyData.length === 0) {
      console.error("No data available after averaging");
      return;
    }

    // Set up scales
    const xScale = d3
      .scaleBand()
      .domain(yearlyData.map((d) => d.year))
      .range([0, innerWidth])
      .padding(0.1);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(yearlyData, (d) => d.avgPoolPrice)])
      .nice()
      .range([innerHeight, 0]);

    // Draw bars
    chartSvg
      .selectAll(".bar")
      .data(yearlyData)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => xScale(d.year))
      .attr("y", (d) => yScale(d.avgPoolPrice))
      .attr("width", xScale.bandwidth())
      .attr("height", (d) => innerHeight - yScale(d.avgPoolPrice))
      .attr("fill", "#0096FF");

    // Add X axis
    chartSvg
      .append("g")
      .attr("transform", `translate(0,${innerHeight})`)
      .call(d3.axisBottom(xScale))
      .selectAll("text")
      .attr("transform", "rotate(-45)")
      .style("text-anchor", "end");

    // Add Y axis
    chartSvg
      .append("g")
      .call(d3.axisLeft(yScale).tickFormat((d) => `$${d.toFixed(2)}`));

    // Add Y axis label
    chartSvg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left)
      .attr("x", 0 - innerHeight / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .attr("font-size", "14px")
      .text("Average Pool Price ($/MWh)")
      .attr("fill", "grey");

    // Add title
    chartSvg
      .append("text")
      .attr("x", innerWidth / 2)
      .attr("y", -margin.top / 2)
      .attr("text-anchor", "middle")
      .attr("font-size", "16px")
      .attr("font-weight", "bold")
      .text("Yearly Average Pool Price")
      .attr("fill", "grey");

    // Add value labels on top of bars
    chartSvg
      .selectAll(".label")
      .data(yearlyData)
      .enter()
      .append("text")
      .attr("class", "label")
      .attr("x", (d) => xScale(d.year) + xScale.bandwidth() / 2)
      .attr("y", (d) => yScale(d.avgPoolPrice) - 5)
      .attr("text-anchor", "middle")
      .attr("font-size", "12px")
      .text((d) => `$${d.avgPoolPrice.toFixed(2)}`)
      .attr("fill", "grey");
  }, [poolPriceData]);

  useEffect(() => {
    renderChart();
  }, [renderChart]);

  useEffect(() => {
    const handleResize = () => {
      renderChart();
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [renderChart]);

  return <svg ref={svgRef} style={{ width: "100%", height: "100%" }}></svg>;
};

export default AesoYearlyAveragePoolPriceGraph;
