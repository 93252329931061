import React, { useEffect, useRef, useCallback } from "react";
import * as d3 from "d3";

const HistoricalHeatRateLineGraph = ({ gasData, poolPriceData }) => {
  const svgRef = useRef();

  const renderChart = useCallback(() => {
    if (
      !gasData ||
      !poolPriceData ||
      gasData.length === 0 ||
      poolPriceData.length === 0
    ) {
      console.error("No data available for rendering");
      return;
    }

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    // Calculate dimensions based on the size of the parent container
    const parentContainer = svg.node().parentNode;
    const parentWidth = parentContainer.clientWidth;
    const parentHeight = parentContainer.clientHeight;
    const margin = { top: 40, right: 60, bottom: 60, left: 70 };

    // Set dimensions considering margins
    const innerWidth = parentWidth - margin.left - margin.right;
    const innerHeight = parentHeight - margin.top - margin.bottom;

    // Create SVG
    const chartSvg = svg
      .attr("width", parentWidth)
      .attr("height", parentHeight)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    const formatMonth = d3.timeFormat("%Y-%m");

    const calculateHeatRate = (
      data,
      dateField,
      poolPriceField,
      settlePriceField
    ) => {
      const groupedData = d3.group(data, (d) => {
        const parsedDate = new Date(d[dateField]);
        if (!parsedDate || d[dateField] === null) {
          console.error(`Failed to parse date: ${d[dateField]}`);
          return null;
        }
        return formatMonth(parsedDate);
      });

      return Array.from(groupedData, ([key, values]) => {
        if (key === null) return null;
        const avgPoolPrice = d3.mean(values, (d) => +d[poolPriceField]);
        const avgSettlePrice = d3.mean(values, (d) => +d[settlePriceField]);
        return {
          marketDate: new Date(key),
          heatRate: avgPoolPrice / avgSettlePrice,
        };
      }).filter((d) => d !== null && !isNaN(d.heatRate));
    };

    // Combine gasData and poolPriceData based on marketDate
    const combinedData = gasData
      .map((gas) => {
        const pool = poolPriceData.find(
          (pool) => pool.marketDate === gas.marketDate
        );
        return {
          marketDate: gas.marketDate,
          settlePrice: gas.settlePrice,
          poolPrice: pool ? pool.poolPrice : null,
        };
      })
      .filter((d) => d.poolPrice !== null);

    const heatRateData = calculateHeatRate(
      combinedData,
      "marketDate",
      "poolPrice",
      "settlePrice"
    );

    if (heatRateData.length === 0) {
      console.error("No data available after calculation");
      return;
    }

    // Set up scales
    const xScale = d3
      .scaleTime()
      .domain(d3.extent(heatRateData, (d) => d.marketDate))
      .range([0, innerWidth]);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(heatRateData, (d) => d.heatRate)])
      .nice()
      .range([innerHeight, 0]);

    // Create line generator
    const line = d3
      .line()
      .x((d) => xScale(d.marketDate))
      .y((d) => yScale(d.heatRate));

    // Draw heat rate line
    chartSvg
      .append("path")
      .datum(heatRateData)
      .attr("fill", "none")
      .attr("stroke", "red")
      .attr("stroke-width", 2)
      .attr("d", line);

    // Add X axis
    chartSvg
      .append("g")
      .attr("transform", `translate(0,${innerHeight})`)
      .call(
        d3
          .axisBottom(xScale)
          .ticks(d3.timeMonth.every(3))
          .tickFormat(d3.timeFormat("%b %Y"))
      )
      .selectAll("text")
      .attr("transform", "rotate(-45)")
      .style("text-anchor", "end");

    // Add Y axis
    chartSvg.append("g").call(d3.axisLeft(yScale));

    // Add Y axis label
    chartSvg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left)
      .attr("x", 0 - innerHeight / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .attr("font-size", "14px")
      .text("Heat Rate (GJ/MWh)")
      .attr("fill", "grey");

    // Add title
    chartSvg
      .append("text")
      .attr("x", innerWidth / 2)
      .attr("y", -margin.top / 2)
      .attr("text-anchor", "middle")
      .attr("font-size", "16px")
      .attr("font-weight", "bold")
      .text("Historical Heat Rate")
      .attr("fill", "grey");
  }, [gasData, poolPriceData]);

  useEffect(() => {
    renderChart();
  }, [renderChart]);

  useEffect(() => {
    const handleResize = () => {
      renderChart();
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [renderChart]);

  return <svg ref={svgRef} style={{ width: "100%", height: "100%" }}></svg>;
};

export default HistoricalHeatRateLineGraph;
