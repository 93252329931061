import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    //https://linguinecode.com/post/react-i18next-namespaces
    ns: ["common", "auth", "commodity", "transaction", "markets", "marketdata"],
    defaultNS: "common",
    fallbackNS: "common",
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: "en",
    resources: {
      en: {
        //translation : {
        common: {
          //App Common
          Home: "Home",
          Admin: "Admin",
          UserAdmin: "User Admin",
          ProductAdmin: "Product Admin",
          SystemAdmin: "System Admin",
          MarketAdmin: "Market Admin",
          TradingAdmin: "Trading Admin",
          MarketControl: "Market Control",
          OpenMarkets: "Open Markets",
          CloseMarkets: "Close Markets",
          MarketsOpened: "Markets Successfully Opened",
          MarketsClosed: "Markets Successfully Closed",
          MarketData: "Market Data",
          DailySummary: "Daily Summary",
          HistoricalMarketData: "Historical Prices",
          HistoricalTrades: "Historical Trades",
          MarketDataSubmitMarks: "Submit Marks",
          MarketDataReviewMarks: "Review Marks",
          MarketDataSubmitTrades: "Submit Trades",
          MarketDataReviewTrades: "Review Trades",
          MarketDataSubmitExternalData: "Submit External Data",
          MarketDataSubmitterDashboard: "Submitter Dashboard",
          Dashboards: "Dashboards",
          AlbertaEnvironmental: "Alberta Environmental",
          AlbertaEnvironmentalDashboard: "Alberta Environmental Dashboard",
          AlbertaElectricity: "Alberta Electricity",
          AlbertaElectricityDashboard: "Alberta ElectricityDashboard",
          AlbertaEnvironmentalTrades: "Alberta Environmental Transfers",
          AlbertaEnvironmentalStatusChanges:
            "Alberta Environmental Status Changes",
          AlbertaEnvironmentalIssues: "Alberta Environmental New Issues",
          AlbertaEnvironmentalRegistry: "Alberta Environmental Registry",
          AlbertaEnergyDaily: "Alberta Energy Daily Report",
          Tools: "Tools",
          About: "About",
          Version: "Version",
          Logout: "Logout",
          SearchPrompt: "Search...",
          UnexpectedError: "An Unexpected Error Occurred",
          ErrorSaving: "Error Saving: ",
          Cancel: "Cancel",
          Confirm: "Confirm",
          Trading: "Trading",
          //App Pages
          NotFound: "Not Found",
          Maintenance: "The System is Currently Undergoing Maintenance",
          Unauthorized: "Not Authorized",
          SubscriptionInquiry:
            "To inquire about subscription features please contact ",
          Homepage: "Welcome to Neutral Data",
          //API Common
          ActiveOnly: "Active Only",
          Active: "Active",
          Save: "Save",
          Created: "Created: ",
          Updated: "Updated: ",
          //API History Common
          History: "History",
          RecordID: "Record",
          UpdatedBy: "Updated By",
          UpdateDate: "Update Date",
          UserAccount: "User Account",
          WriteDate: "Write Date",
          EventID: "Event ID",
          //UserProfile
          UserProfile: "User Profile",
          Login: "Login",
          FirstName: "First Name",
          LastName: "Last Name",
          Email: "Email",
          UserAccountId: "User Account ID",
          Organization: "Organization",
          InClaims: "In Claims",
          Role: "Role",
          Subscription: "Subscription",
          SubscriptionType: "Type",
          Contributor: "Contributor",
          Viewer: "Viewer",
        },
        auth: {
          //User Accounts
          Users: "Users",
          UserAccounts: "User Accounts",
          AddUserAccount: "Add User Account",
          NewUser: "New User",
          RetrievedUserAccount_one: "{{count}} User Account Retrieved",
          RetrievedUserAccount_other: "{{count}} User Accounts Retrieved",
          RetrievedUserAccount_zero: "No User Accounts Retrieved",
          Login: "Login",
          FirstName: "First Name",
          LastName: "Last Name",
          Email: "Email",
          UserUpdated: "User Updated",
          UserAdded: "User Added",
          UserAccountID: "User Account ID: ",
          RoleAdded: "Role Added",
          RoleRevoked: "Role Revoked",
          RoleAdditionFailed: "Role Addition Failed",
          RoleRevocationFailed: "Role Revocation Failed",
          OrganizationAdded: "Organization Added",
          OrganizationRemoved: "Organization Removed",
          OrganizationAdditionFailed: "Organization Addition Failed",
          OrganizationRemovalFailed: "Organization Removal Failed",
          SetPasswordSent: "Password Reset Request Sent to User",
          ConfirmSetPassword: "Send Password Reset Request to User?",

          //Roles
          Roles: "Roles",
          AddRole: "Add Role",
          NewRole: "New Role",
          RetrievedRole_one: "{{count}} Role Retrieved",
          RetrievedRole_other: "{{count}} Roles Retrieved",
          RetrievedRole_zero: "No Roles Retrieved",
          RoleName: "Name",
          RoleDescription: "Description",
          RoleUpdated: "Role Updated",
          RoleID: "Role ID: ",
          ChildRoles: "Child Roles",
          ChildRoleAdded: "Child Role Added",
          ChildRoleRemoved: "Child Role Removed",
          ChildRoleAdditionFailed: "Child Role Addition Failed",
          ChildRoleRemovalFailed: "Child Role Removal Failed",
          //Organizations
          Organizations: "Organizations",
          AddOrganization: "Add Organization",
          NewOrganization: "New Organization",
          RetrievedOrganization_one: "{{count}} Organization Retrieved",
          RetrievedOrganization_other: "{{count}} Organizations Retrieved",
          RetrievedOrganization_zero: "No Organizations Retrieved",
          OrganizationName: "Name",
          OrganizationNameFull: "Organization Name",
          OrganizationShortName: "Short Name",
          OrganizationWebsite: "Website",
          OrganizationUpdated: "Organization Updated",
          OrganizationID: "Organization ID: ",
          //Subscriptions:
          AddOrganizationSubscription: "Add Organization Market Subscription",
          AddUserAccountSubscription: "Add User Account Market Subscription",
          OrganizationSubscription: "Organization Market Subscription",
          OrganizationSubscriptions: "Organization Market Subscriptions",
          UserAccountSubscription: "User Account Market Subscription",
          UserAccountSubscriptions: "User Account Market Subscriptions",
          SubscriptionName: "Subscription Name",
          SubscribableName: "Subscribable Name",
          UserAccountName: "User Account",
          Compensated: "Compensated",
          Contributor: "Contributor",
          OrganizationSubscriptionID: "Organization Market Subscription ID:",
          UserAccountSubscriptionID: "User Account Subscription ID:",
          MarketName: "Market Name",
          OrganizationSubscriptionUpdated:
            "Organization Market Subscription Updated",
          OrganizationSubscriptionAdded:
            "Organization Market Subscription Added",
          UserAccountSubscriptionUpdated:
            "User Account Market Subscription Updated",
          UserAccountSubscriptionAdded:
            "User Account Market Subscription Added",
          RetrievedOrganizationSubscription_one:
            "{{count}} Organization Market Subscription Retrieved",
          RetrievedOrganizationSubscription_other:
            "{{count}} Organization Market Subscriptions Retrieved",
          RetrievedOrganizationSubscription_zero:
            "No Organization Market Subscriptions Retrieved",
          RetrievedUserAccountSubscription_one:
            "{{count}} User Account Market Subscription Retrieved",
          RetrievedUserAccountSubscription_other:
            "{{count}} User Account Market Subscriptions Retrieved",
          RetrievedUserAccountSubscription_zero:
            "No User Account Market Subscriptions Retrieved",
          SubscriptionType: "Type",
        },
        products: {
          //Commodity Classes
          CommodityClass: "Commodity Class",
          CommodityClasses: "Commodity Classes",
          AddCommodityClass: "Add Commodity Class",
          NewCommodityClass: "New Commodity Class",
          RetrievedCommodityClass_one: "{{count}} Commodity Class Retrieved",
          RetrievedCommodityClass_other:
            "{{count}} Commodity Classes Retrieved",
          RetrievedCommodityClass_zero: "No Commodity Classes Retrieved",
          CommodityClassName: "Name",
          CommodityClassDescription: "Description",
          CommodityClassUpdated: "Commodity Class Updated",
          CommodityClassAdded: "Commodity Class Added",
          CommodityClassID: "Commodity Class ID: ",
          //Commodities
          Commodity: "Commodity",
          Commodities: "Commodities",
          AddCommodity: "Add Commodity",
          NewCommodity: "New Commodity",
          RetrievedCommodity_one: "{{count}} Commodity Retrieved",
          RetrievedCommodity_other: "{{count}} Commodities Retrieved",
          RetrievedCommodity_zero: "No Commodities Retrieved",
          CommodityName: "Name",
          CommodityDescription: "Description",
          CommodityUpdated: "Commodity Updated",
          CommodityAdded: "Commodity Added",
          CommodityID: "Commodity ID: ",
          //Products
          Product: "Product",
          Products: "Products",
          AddProduct: "Add Product",
          NewProduct: "New Product",
          RetrievedProduct_one: "{{count}} Product Retrieved",
          RetrievedProduct_other: "{{count}} Products Retrieved",
          RetrievedProduct_zero: "No Products Retrieved",
          ProductName: "Name",
          ProductShortName: "Short Name",
          ProductUpdated: "Product Updated",
          ProductAdded: "Product Added",
          ProductID: "Product ID: ",
          //Aliases
          Alias: "Alias",
          Aliases: "Aliases",
          AddAlias: "Add Alias",
          NewAlias: "New Alias",
          RetrievedAlias_one: "{{count}} Alias Retrieved",
          RetrievedAlias_other: "{{count}} Aliases Retrieved",
          RetrievedAlias_zero: "No Aliases Retrieved",
          AliasName: "Name",
          AliasShortName: "Short Name",
          AliasUpdated: "Alias Updated",
          AliasAdded: "Alias Added",
          AliasID: "Alias ID: ",
          Organization: "Organization",
          //Quality Attributes
          QualityAttribute: "Quality Attribute",
          QualityAttributes: "Quality Attributes",
          AddQualityAttribute: "Add Quality Attribute",
          NewQualityAttribute: "New Quality Attribute",
          RetrievedQualityAttribute_one:
            "{{count}} Quality Attribute Retrieved",
          RetrievedQualityAttribute_other:
            "{{count}} Quality Attributes Retrieved",
          RetrievedQualityAttribute_zero: "No Quality Attributes Retrieved",
          QualityAttributeName: "Name",
          QualityAttributeShortName: "Short Name",
          QualityAttributeUpdated: "Quality Attribute Updated",
          QualityAttributeAdded: "Quality Attribute Added",
          QualityAttributeID: "Quality Attribute ID: ",
          //Quality Attribute Values
          QualityAttributeValue: "Quality Attribute Value",
          QualityAttributeValues: "Quality Attribute Values",
          AddQualityAttributeValue: "Add Quality Attribute Value",
          NewQualityAttributeValue: "New Quality Attribute Value",
          RetrievedQualityAttributeValue_one:
            "{{count}} Quality Attribute Value Retrieved",
          RetrievedQualityAttributeValue_other:
            "{{count}} Quality Attribute Values Retrieved",
          RetrievedQualityAttributeValue_zero:
            "No Quality Attribute Values Retrieved",
          QualityAttributeValueName: "Name",
          QualityAttributeValueShortName: "Short Name",
          QualityAttributeValueUpdated: "Quality Attribute Value Updated",
          QualityAttributeValueAdded: "Quality Attribute Value Added",
          QualityAttributeValueID: "Quality Attribute Value ID: ",
        },
        markets: {
          //Markets
          Market: "Market",
          Markets: "Markets",
          MarketGroup: "Market Group",
          MarketGroups: "Market Groups",
          AddMarketGroup: "Add Market Group",
          MarketGroupName: "Market Group Name",
          AddMarket: "Add Market",
          NewMarket: "New Market",
          RetrievedMarket_one: "{{count}} Market Retrieved",
          RetrievedMarket_other: "{{count}} Markets Retrieved",
          RetrievedMarket_zero: "No Markets Retrieved",
          RetrievedMarketGroup: "Retrieved Market Groups",
          MarketName: "Name",
          MarketDescription: "Description",
          MarketUpdated: "Market Updated",
          MarketAdded: "Market Added",
          MarketID: "Market ID: ",
          //Currencies
          Currency: "Currency",
          Currencies: "Currencies",
          AddCurrency: "Add Currency",
          NewCurrency: "New Currency",
          RetrievedCurrencies_one: "{{count}} Currency Retrieved",
          RetrievedCurrencies_other: "{{count}} Currencies Retrieved",
          RetrievedCurrencies_zero: "No Currencies Retrieved",
          CurrencyName: "Name",
          CurrencySymbol: "Currency Symbol",
          CurrencyDescription: "Description",
          CurrencyUpdated: "Currency Updated",
          CurrencyAdded: "Currency Added",
          CurrencyID: "Currency ID: ",
          //PriceBases
          PriceBasis: "Price Basis",
          PriceBases: "Price Bases",
          AddPriceBasis: "Add Price Basis",
          NewPriceBasis: "New Price Basis",
          RetrievedPriceBasis_one: "{{count}} Price Basis Retrieved",
          RetrievedPriceBasis_other: "{{count}} Price Bases Retrieved",
          RetrievedPriceBasis_zero: "No Price Bases Retrieved",
          PriceBasisName: "Name",
          PriceBasisDescription: "Description",
          PriceBasisUpdated: "Price Basis Updated",
          PriceBasisAdded: "Price Basis Added",
          PriceBasisID: "Price Basis ID: ",
          //Term Schedules
          TermSchedule: "Term Schedule",
          TermSchedules: "Term Schedules",
          AddTermSchedule: "Add Term Schedule",
          NewTermSchedule: "New Term Schedule",
          RetrievedTermSchedule_one: "{{count}} Term Schedule Retrieved",
          RetrievedTermSchedule_other: "{{count}} Term Schedules Retrieved",
          RetrievedTermSchedule_zero: "No Term Schedules Retrieved",
          TermScheduleName: "Name",
          TermScheduleDescription: "Description",
          ForwardReportingPeriodicity: "Forward Reporting Periodicity",
          HistoricalReportingPeriodicity: "Historical Reporting Periodicity",
          TermScheduleUpdated: "Term Schedule Updated",
          TermScheduleAdded: "Term Schedule Added",
          TermScheduleID: "Term Schedule ID: ",
          //Terms
          Term: "Term",
          Terms: "Terms",
          AddTerm: "Add Term",
          NewTerm: "New Term",
          RetrievedTerm_one: "{{count}} Term Retrieved",
          RetrievedTerm_other: "{{count}} Terms Retrieved",
          RetrievedTerm_zero: "No Terms Retrieved",
          TermName: "Name",
          StartDate: "Start Date",
          EndDate: "End Date",
          EffectiveDate: "Effective Date",
          ExpiryDate: "Expiry Date",
          TermDescription: "Description",
          TermUpdated: "Term Updated",
          TermAdded: "Term Added",
          TermID: "Term ID: ",
          //Uom Types
          UomType: "Uom Type",
          UomTypes: "Uom Types",
          AddUomType: "Add Uom Type",
          NewUomType: "New Uom Type",
          RetrievedUomType_one: "{{count}} Uom Type Retrieved",
          RetrievedUomType_other: "{{count}} Uom Types Retrieved",
          RetrievedUomType_zero: "No Uom Types Retrieved",
          UomTypeName: "Name",
          UomTypeCode: "Uom Type Code",
          UomTypeConversion: "Uom Type Conversion",
          UomTypeDescription: "Description",
          UomTypeUpdated: "Uom Type Updated",
          UomTypeAdded: "Uom Type Added",
          UomTypeID: "Uom Type ID: ",
          //Uoms
          Uom: "Uom",
          Uoms: "Uoms",
          AddUom: "Add Uom",
          NewUom: "New Uom",
          RetrievedUom_one: "{{count}} Uom Retrieved",
          RetrievedUom_other: "{{count}} Uoms Retrieved",
          RetrievedUom_zero: "No Uoms Retrieved",
          UomName: "Name",
          UomCode: "Uom Code",
          UomConversion: "Uom Conversion",
          UomDescription: "Description",
          UomUpdated: "Uom Updated",
          UomAdded: "Uom Added",
          UomID: "Uom ID: ",
          //Venues
          Venue: "Venue",
          Venues: "Venues",
          AddVenue: "Add Venue",
          NewVenue: "New Venue",
          RetrievedVenue_one: "{{count}} Venue Retrieved",
          RetrievedVenue_other: "{{count}} Venues Retrieved",
          RetrievedVenue_zero: "No Venues Retrieved",
          VenueName: "Name",
          VenueDescription: "Description",
          VenueUpdated: "Venue Updated",
          VenueAdded: "Venue Added",
          VenueID: "Venue ID: ",
          TimeZone: "Time Zone",
          //MarketAttributes
          AddAttribute: "Add Attribute",
          MarketAttribute: "Market Attribute",
          MarketAttributes: "Market Attributes",
          AddMarketAttribute: "Add Market Attribute",
          NewMarketAttribute: "New Market Attribute",
          RetrievedMarketAttribute_one: "{{count}} Market Attribute Retrieved",
          RetrievedMarketAttribute_other:
            "{{count}} Market Attributes Retrieved",
          RetrievedMarketAttribute_zero: "No Market Attributes Retrieved",
          MarketAttributeName: "Name",
          MarketAttributeDescription: "Description",
          MarketAttributeUpdated: "Market Attribute Updated",
          MarketAttributeAdded: "Market Attribute Added",
          MarketAttributeID: "Market Attribute ID: ",
          PricePrecision: "Price Precision",
          VolumePrecision: "Volume Precision",
        },
        marketdata: {
          ApproveAll: "Approve All",
          RejectAll: "Reject All",
          MarketDataProduct: "Product",
          SubmittingOrganizationName: "Submitter",
          MarketDate: "Market Date",
          UpdateTrade: "Update Trade",
          AddTrade: "Add Trade",
          ViewTrade: "View Trade",
          UpdateMark: "Update Mark",
          AddMark: "Add Mark",
          ViewMark: "View Mark",
          ReviewMarks: "Review Marks",
          ReviewTrades: "Review Trades",
          TradeHistory: "Trade History",
          MarketDataHistory: "Market Data History",
          MarkUpdated: "Successfully Updated Mark",
          MarkAdded: "Successfully Added Mark",
          MarkApproved: "Successfully Approved Mark",
          MarksApproved: "Successfully Approved {{count}} Marks",
          MarkDeleted: "Successfully Deleted Mark",
          MarkRejected: "Successfully Rejected Mark",
          MarksRejected: "Successfully Rejected {{count}} Marks",
          TradeUpdated: "Successfully Updated Trade",
          TradeAdded: "Successfully Added Trade",
          TradeApproved: "Successfully Approved Trade",
          TradeRejected: "Successfully Rejected Trade",
          BuyerOrganization: "Buyer",
          SellerOrganization: "Seller",
          TradeDate: "Trade Date",
          TradeState: "Trade State",
          PriceBasis: "Price Basis",
          Term: "Term",
          Product: "Product",
          Approve: "Approve",
          Approved: "Approved",
          Reject: "Reject",
          Rejected: "Rejected",
          Price: "Price",
          Bid: "Bid",
          Offer: "Offer",
          Open: "Open",
          High: "High",
          Low: "Low",
          Close: "Close",
          Volume: "Volume",
          Override: "Override",
          UpdateDate: "Updated Date",
          MarkState: "State",
          SpotHistory: "Spot History",
          ForwardCurve: "Curve",
          RecentTrades: "Recent Trades",
          TimeZone: "Time Zone",
          QualityAttribute: "Attribute",
          QualityAttributeDifferentials: "Attribute Differentials",
          RetrievedMarks_one: "{{count}} Marks Retrieved",
          RetrievedMarks_other: "{{count}} Marks Retrieved",
          RetrievedMarks_zero: "No Marks Retrieved",
          SubmittedOnly: "Submitted Only",
          PriceBidOrOfferRequired:
            "Value must be provided for one of Price, Bid or Offer",
          //AlbertaEnvironmentalTrades
          AlbertaEnvironmentalTrades: "Alberta Environmental Transfers",
          AlbertaEnvironmentalStatusChanges:
            "Alberta Environmental Status Changes",
          AlbertaEnvironmentalIssues: "Alberta Environmental New Issues",
          Aeor: "AEOR",
          Epc: "EPC",
          TransactionId: "Transaction Id",
          TransactionDate: "Transaction Date",
          Buyer: "Buyer",
          Seller: "Seller",
          Quantity: "Quantity",
          Registry: "Registry",
          Vintage: "Vintage",
          QuantificationProtocol: "Quantification Protocol",
          OldStatuses: "Old Statuses",
          NewStatuses: "New Statuses",
          SerialStart: "Serial Start",
          SerialEnd: "Serial End",
          StartDate: "Start Date",
          EndDate: "End Date",
          RegistryDate: "Registry Date",
          OldEmissionOffsetStatus: "Old Status",
          NewEmissionOffsetStatus: "New Status",
          RetrievedAlbertaEnvironmentalTrades_one:
            "{{count}} Transfers Retrieved",
          RetrievedAlbertaEnvironmentalTrades_other:
            "{{count}} Transfers Retrieved",
          RetrievedAlbertaEnvironmentalTrades_zero: "No Transfers Retrieved",
          RetrievedAlbertaEnvironmentalIssues_one: "{{count}} Issues Retrieved",
          RetrievedAlbertaEnvironmentalIssues_other:
            "{{count}} Issues Retrieved",
          RetrievedAlbertaEnvironmentalIssues_zero: "No Issues Retrieved",
          RetrievedAlbertaEnvironmentalStatusChanges_one:
            "{{count}} Status Changes Retrieved",
          RetrievedAlbertaEnvironmentalStatusChanges_other:
            "{{count}} Status Changes Retrieved",
          RetrievedAlbertaEnvironmentalStatusChanges_zero:
            "No Status Changes Retrieved",
          Excel: "Excel",
          //AlbertaEnvironmentalRegistry
          AlbertaEnvironmentalRegistry: "Alberta Environmental Registry",
          ProjectIdenftifier: "Project ID",
          CurrentOwner: "Current Owner",
          EmissionOffsetProjectDeveloper: "Project Developer",
          ActivityStart: "Activity Start",
          ComplianceYear: "Compliance Year",
          ExpiryDate: "Expiry Date",
          Facility: "Facility",
          OffsetStartDate: "Offset Start Date",
          OffsetEndDate: "Offset End Date",
          Status: "Status",
          Title: "Title",
          VerifierCompany: "Verifier",
          RetrievedAlbertaEnvironmentalRegistry_one:
            "{{count}} Records Retrieved",
          RetrievedAlbertaEnvironmentalRegistry_other:
            "{{count}} Records Retrieved",
          RetrievedAlbertaEnvironmentalRegistry_zero: "No Records Retrieved",
          GenerateMarks: "Generate Marks",
          GeneratedNMarks: "Generated {{numberOfMarks}} Marks",
          ErrorGeneratingSettlements: "Error Generating Settlements",
          CopyPriorMarks: "Duplicate Prior Marks",
          ConfirmDeleteMark: "Are you sure you want to delete this mark?",
          //ExternalData
          LastSubmittedDate: "Last Submitted Date",
          NoSubmissionForThisDate: "No submission for this date",
          NgxSettlement: "Ngx Settlement",
          SuccessfullySubmittedDataForN:
            "Successfully Submitted {{dataType}} Data",
          FailedToSubmitDataForN: "Failed to submit {{dataType}} data: ",
          NgxSettlementDataPlaceholder:
            "Copy and paste ONLY the settlements table into this textbox. Pasted data should be formatted as follows: \n\nMarket\tBegin Date\tEnd Date\t# Trades\tTotal Volume\tOpen\tHigh\tLow\tSettle\tNet OI\nNGX Phys, ID, 7a (US/MM), AB-NIT\t2024-12-01\t2024-12-31\t0\t0\t\t\t\t0.0423\t61,187,800\nNGX Phys, ID, 7a (US/MM), AB-NIT\t2025-01-01\t2025-01-31\t0\t0\t\t\t\t0.0015\t33,635,000\nNGX Phys, ID, 7a (US/MM), AB-NIT\t2025-02-01\t2025-02-28\t0\t0\t\t\t\t0.0015\t23,660,000\nNGX Phys, ID, 7a (US/MM), AB-NIT\t2025-03-01\t2025-03-31\t0\t0\t\t\t\t0.0008\t26,815,000\netc.",
          NgxSettlementDataInformation1: "1. Click the link(s) below:",
          NgxSettlementDataInformation2:
            "2. In each new window, complete the reCAPTCHA, choose the relevant market and time period and hit 'Submit'\n\n 3. Copy ONLY the table contents\n\n 4. Paste into the text box",
          ExternalDataSource: "Data Source",
          SubmitExternalDataHelpModalTitle: "Submitting External Data",
          ExternalDataSourceLinks: "Data Source Links",
          SubmittedDataModalTitle:
            "Submitted Data for {{externalDataSource}} on {{date}}",
          //PowerReport
          TermName: "Term",
          Current: "Yesterday Settle",
          YesterdayDelta: "Yesterday Change",
          MtdAverage: "MTD Average",
          YtdAverage: "YTD Average",
          YtdLow: "YTD Low",
          YtdHigh: "YTD High",
          Date: "Date",
          WeightedAve7x24: "Weighted Avg. 7x24",
          WeightedAve7x24Change: "Change",
          MaxPriceHourly: "Max Price Hourly",
          NumericAve7x24: "Numeric Avg. 7x24",
          NumericAve7x24Change: "Change",
          Peak7x16: "Peak 7x16",
          Peak7x16Change: "Change",
          OffPeak7x16: "Off Peak 7x16",
          OffPeak7x16Change: "Change",
          AbSpotNatGas: "Ab. Spot NatGas",
          WeightedAverage7x24HeatRate: "Weighted Avg 7x24 Heat Rate",
          Inventory: "Inventory",
          DailyChange: "Daily Change",
          PercentFull: "Percent Full",
          Capacity: "Capacity",
        },
        trading: {
          //TradingMarkets
          TradingMarket: "Trading Market",
          TradingMarkets: "Trading Markets",
          AddTradingMarket: "Add Trading Market",
          NewTradingMarket: "New Trading Market",
          RetrievedTradingMarket_one: "{{count}} Trading Market Retrieved",
          RetrievedTradingMarket_other: "{{count}} Trading Markets Retrieved",
          RetrievedTradingMarket_zero: "No Trading Markets Retrieved",
          TradingMarketName: "Name",
          TradingMarketDescription: "Description",
          TradingMarketUpdated: "Trading Market Updated",
          TradingMarketAdded: "Trading Market Added",
          TradingMarketID: "TradingMarket ID: ",
          ACtive: "Active",
          Halted: "Halted",
          AllowPick: "Pick",
          AllowAllOrNone: "All or None",
          AllowFillOrKill: "Fill Or Kill",
          WithdrawOnCrossTrade: "Withdraw on Crosstrade",
          ApplyCounterpartyWhitelist: "Whitelist",
          //Market/Trade/Order Grids
          MarketName: "Market",
          TermName: "Term",
          Attributes: "Attributes",
          BidVolume: "Bid Volume",
          BidPrice: "Bid Price",
          OfferPrice: "Offer Price",
          OfferVolume: "Offer Volume",
          QualityAttributes: "Quality Attributes",
          //MarketForm
          Volume: "Volume",
          Price: "Price",
          Organization: "Organization",
          Commodity: "Commodity",
          Product: "Product",
          Market: "Market",
          PriceBasis: "Price Basis",
          Buy: "Buy",
          Sell: "Sell",
          FillOrKill: "Fill Or Kill",
          AllOrNone: "All Or None",
          TradeId: "Trade ID",
          OrderId: "Order ID",
          OrderDate: "Order Date",
          TradeDate: "Trade Date",
          OrderState: "Order State",
        },
        //}
      },
    },
  });
