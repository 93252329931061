import React, { useEffect, useRef, useCallback } from "react";
import * as d3 from "d3";

const NaturalGasPoolPriceLineGraph = ({ gasData, poolPriceData }) => {
  const svgRef = useRef();

  const renderChart = useCallback(() => {
    if (
      !gasData ||
      !poolPriceData ||
      gasData.length === 0 ||
      poolPriceData.length === 0
    ) {
      console.error("No data available for rendering");
      return;
    }

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    // Calculate dimensions based on the size of the parent container
    const parentContainer = svg.node().parentNode;
    const parentWidth = parentContainer.clientWidth;
    const parentHeight = parentContainer.clientHeight;
    const margin = { top: 40, right: 80, bottom: 70, left: 70 };

    // Set dimensions considering margins
    const innerWidth = parentWidth - margin.left - margin.right;
    const innerHeight = parentHeight - margin.top - margin.bottom;

    // Create SVG
    const chartSvg = svg
      .attr("width", parentWidth)
      .attr("height", parentHeight)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    // Parse dates and average by month
    //const parseDate = d3.utcParse("%Y-%m-%dT%H:%M:%SZ");
    const formatMonth = d3.timeFormat("%Y-%m");

    const averageByMonth = (data, dateField, valueField) => {
      const groupedData = d3.group(data, (d) => {
        //const parsedDate = parseDate(d[dateField]);
        const parsedDate = new Date(d[dateField]);
        if (!parsedDate || d[dateField] === null) {
          console.error(`Failed to parse date: ${d[dateField]}`);
          return null;
        }
        return formatMonth(parsedDate);
      });

      return Array.from(groupedData, ([key, values]) => {
        if (key === null) return null;
        return {
          marketDate: new Date(key),
          [valueField]: d3.mean(values, (d) => {
            const value = +d[valueField];
            if (isNaN(value)) {
              console.error(`Invalid ${valueField} value:`, d[valueField]);
              return null;
            }
            return value;
          }),
        };
      }).filter((d) => d !== null);
    };

    const averagedGasData = averageByMonth(
      gasData,
      "marketDate",
      "settlePrice"
    );
    const averagedPoolPriceData = averageByMonth(
      poolPriceData,
      "marketDate",
      "poolPrice"
    );

    if (averagedGasData.length === 0 || averagedPoolPriceData.length === 0) {
      console.error("No data available after averaging");
      return;
    }

    // Set up scales
    const xScale = d3
      .scaleTime()
      .domain(
        d3.extent(
          [...averagedGasData, ...averagedPoolPriceData],
          (d) => d.marketDate
        )
      )
      .range([0, innerWidth]);

    const yScaleGas = d3
      .scaleLinear()
      .domain([0, d3.max(averagedGasData, (d) => d.settlePrice)])
      .nice()
      .range([innerHeight, 0]);

    const yScalePool = d3
      .scaleLinear()
      .domain([0, d3.max(averagedPoolPriceData, (d) => d.poolPrice)])
      .nice()
      .range([innerHeight, 0]);

    // Create line generators
    const lineGas = d3
      .line()
      .x((d) => xScale(d.marketDate))
      .y((d) => yScaleGas(d.settlePrice));

    const linePool = d3
      .line()
      .x((d) => xScale(d.marketDate))
      .y((d) => yScalePool(d.poolPrice));

    // Draw pool price line
    chartSvg
      .append("path")
      .datum(averagedPoolPriceData)
      .attr("fill", "none")
      .attr("stroke", "red")
      .attr("stroke-width", 2)
      .attr("d", linePool);

    // Draw gas price line
    chartSvg
      .append("path")
      .datum(averagedGasData)
      .attr("fill", "none")
      .attr("stroke", "#0096FF")
      .attr("stroke-width", 2)
      .attr("d", lineGas);

    // Add X axis
    chartSvg
      .append("g")
      .attr("transform", `translate(0,${innerHeight})`)
      .call(
        d3
          .axisBottom(xScale)
          .ticks(d3.timeMonth.every(3))
          .tickFormat(d3.timeFormat("%b %Y"))
      )
      .selectAll("text")
      .attr("transform", "rotate(-45)")
      .style("text-anchor", "end");

    // Add left Y axis (Pool Price)
    chartSvg
      .append("g")
      .call(d3.axisLeft(yScalePool).tickFormat((d) => `$${d.toFixed(2)}`));

    // Add right Y axis (Gas Price)
    chartSvg
      .append("g")
      .attr("transform", `translate(${innerWidth},0)`)
      .call(d3.axisRight(yScaleGas).tickFormat((d) => `$${d.toFixed(2)}`));

    // Add title
    chartSvg
      .append("text")
      .attr("x", innerWidth / 2)
      .attr("y", -margin.top / 2)
      .attr("text-anchor", "middle")
      .attr("font-size", "16px")
      .attr("font-weight", "bold")
      .text("AESO 7x24 vs ICE Spot Natural Gas")
      .attr("fill", "grey");

    // Add Y axis label for Pool Price
    chartSvg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left)
      .attr("x", 0 - innerHeight / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .attr("font-size", "14px")
      .text("Pool Price ($/MWh)")
      .attr("fill", "grey");

    // Add Y axis label for Gas Price
    chartSvg
      .append("text")
      .attr("transform", "rotate(90)")
      .attr("y", -innerWidth - margin.right + 20)
      .attr("x", innerHeight / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .attr("font-size", "14px")
      .text("Gas Price ($/GJ)")
      .attr("fill", "grey");

    // Add legend
    const legend = chartSvg
      .append("g")
      .attr("font-family", "sans-serif")
      .attr("font-size", 10)
      .attr("text-anchor", "end")
      .attr("transform", `translate(${innerWidth}, ${-10})`);

    const legendItems = [
      { label: "Pool Price", color: "red" },
      { label: "Gas Price", color: "#0096FF" },
    ];

    legendItems.forEach((item, i) => {
      const legendItem = legend
        .append("g")
        .attr("transform", `translate(${-i * 100}, 0)`);

      legendItem
        .append("line")
        .attr("x1", -30)
        .attr("x2", -10)
        .attr("stroke", item.color)
        .attr("stroke-width", 2);

      legendItem
        .append("text")
        .attr("x", -35)
        .attr("dy", "0.32em")
        .text(item.label)
        .attr("fill", "grey");
    });
  }, [gasData, poolPriceData]);

  useEffect(() => {
    renderChart();
  }, [renderChart]);

  useEffect(() => {
    const handleResize = () => {
      renderChart();
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [renderChart]);

  return <svg ref={svgRef} style={{ width: "100%", height: "100%" }}></svg>;
};

export default NaturalGasPoolPriceLineGraph;
