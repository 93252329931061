import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import Table from "../../common/components/table/table";
import { withTranslation } from "react-i18next";
import _ from "lodash";

class AesoSummaryTable extends Component {
  columns = [
    { path: "item", className: "table-bold" },
    { path: "date", label: this.props.t("Date") },
    {
      path: "weightedAve7x24",
      label: this.props.t("WeightedAve7x24"),
      className: "right-align",
    },
    {
      path: "weightedAve7x24Change",
      label: this.props.t("WeightedAve7x24Change"),
      content: (data) => (
        <span
          style={{
            color: data.weightedAve7x24ChangeRaw >= 0 ? "green" : "red",
          }}
        >
          <FontAwesome
            name={data.weightedAve7x24ChangeRaw >= 0 ? "sort-asc" : "sort-desc"}
          />{" "}
          {data.weightedAve7x24Change}
        </span>
      ),
      className: "right-align",
    },
    {
      path: "maxPriceHourly",
      label: this.props.t("MaxPriceHourly"),
      className: "right-align",
    },
    {
      path: "numericAve7x24",
      label: this.props.t("NumericAve7x24"),
      content: (data) => (
        <span style={{ color: data.uomSymbol === "MW" ? "RoyalBlue" : "" }}>
          {data.numericAve7x24}
        </span>
      ),
      className: "right-align",
    },
    {
      path: "numericAve7x24Change",
      label: this.props.t("NumericAve7x24Change"),
      content: (data) => (
        <span
          style={{ color: data.numericAve7x24ChangeRaw >= 0 ? "green" : "red" }}
        >
          <FontAwesome
            name={data.numericAve7x24ChangeRaw >= 0 ? "sort-asc" : "sort-desc"}
          />{" "}
          {data.numericAve7x24Change}
        </span>
      ),
      className: "right-align",
    },
    {
      path: "peak7x16",
      label: this.props.t("Peak7x16"),
      content: (data) => (
        <span style={{ color: data.uomSymbol === "MW" ? "RoyalBlue" : "" }}>
          {data.peak7x16}
        </span>
      ),
      className: "right-align",
    },
    {
      path: "peak7x16Change",
      label: this.props.t("Peak7x16Change"),
      content: (data) => (
        <span style={{ color: data.peak7x16ChangeRaw >= 0 ? "green" : "red" }}>
          <FontAwesome
            name={data.peak7x16ChangeRaw >= 0 ? "sort-asc" : "sort-desc"}
          />{" "}
          {data.peak7x16Change}
        </span>
      ),
      className: "right-align",
    },
    {
      path: "offPeak7x16",
      label: this.props.t("OffPeak7x16"),
      content: (data) => (
        <span style={{ color: data.uomSymbol === "MW" ? "RoyalBlue" : "" }}>
          {data.offPeak7x16}
        </span>
      ),
      className: "right-align",
    },
    {
      path: "offPeak7x16Change",
      label: this.props.t("OffPeak7x16Change"),
      content: (data) => (
        <span
          style={{ color: data.offPeak7x16ChangeRaw >= 0 ? "green" : "red" }}
        >
          <FontAwesome
            name={data.offPeak7x16ChangeRaw >= 0 ? "sort-asc" : "sort-desc"}
          />{" "}
          {data.offPeak7x16Change}
        </span>
      ),
      className: "right-align",
    },
    {
      path: "abSpotNatGas",
      label: this.props.t("AbSpotNatGas"),
      content: (data) => (
        <span style={{ color: "SlateBlue" }}>{data.abSpotNatGas}</span>
      ),
      className: "right-align",
    },
    {
      path: "weightedAverage7x24HeatRate",
      label: this.props.t("WeightedAverage7x24HeatRate"),
      content: (data) => (
        <span style={{ color: "SlateBlue" }}>
          {data.weightedAverage7x24HeatRate}
        </span>
      ),
      className: "right-align",
    },
  ];

  mapToViewModel(summaryData) {
    const { pricePrecision, currencySymbol } = this.props;

    const dateFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
    };

    return summaryData.map((data) => ({
      sortOrder: data.sortOrder,
      item: data.item,
      date: new Date(data.beginDateTime).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      weightedAve7x24: data.weightedAverage
        ? data.currencySymbol +
          data.weightedAverage.toFixed(data.pricePrecision) +
          " " +
          data.uomSymbol
        : "-",
      weightedAve7x24ChangeRaw:
        data.weightedAverage && data.weightedAverageYesterday
          ? data.weightedAverage - data.weightedAverageYesterday
          : null,
      weightedAve7x24Change:
        data.weightedAverage && data.weightedAverageYesterday
          ? data.currencySymbol +
            (data.weightedAverage - data.weightedAverageYesterday).toFixed(
              data.pricePrecision
            ) +
            " " +
            data.uomSymbol
          : "-",
      maxPriceHourly: data.maxPriceHourly
        ? data.currencySymbol +
          data.maxPriceHourly.toFixed(
            data.pricePrecision + " " + data.uomSymbol
          )
        : "-",
      numericAve7x24: data.numericAverage
        ? data.currencySymbol +
          data.numericAverage.toFixed(data.pricePrecision) +
          " " +
          data.uomSymbol
        : "-",
      numericAve7x24ChangeRaw:
        data.numericAverage && data.numericAverageYesterday
          ? data.numericAverage - data.numericAverageYesterday
          : null,
      numericAve7x24Change:
        data.numericAverage && data.numericAverageYesterday
          ? data.currencySymbol +
            (data.numericAverage - data.numericAverageYesterday).toFixed(
              data.pricePrecision
            ) +
            " " +
            data.uomSymbol
          : "-",
      peak7x16: data.onPeak7x16Average
        ? data.currencySymbol +
          data.onPeak7x16Average.toFixed(data.pricePrecision) +
          " " +
          data.uomSymbol
        : "-",
      peak7x16ChangeRaw:
        data.onPeak7x16Average && data.onPeak7x16AverageYesterday
          ? data.onPeak7x16Average - data.onPeak7x16AverageYesterday
          : null,
      peak7x16Change:
        data.onPeak7x16Average && data.onPeak7x16AverageYesterday
          ? data.currencySymbol +
            (data.onPeak7x16Average - data.onPeak7x16AverageYesterday).toFixed(
              data.pricePrecision
            ) +
            " " +
            data.uomSymbol
          : "-",
      offPeak7x16: data.offPeak7x16Average
        ? data.currencySymbol +
          data.offPeak7x16Average.toFixed(data.pricePrecision) +
          " " +
          data.uomSymbol
        : "-",
      offPeak7x16ChangeRaw:
        data.offPeak7x16Average && data.offPeak7x16AverageYesterday
          ? data.offPeak7x16Average - data.offPeak7x16AverageYesterday
          : null,
      offPeak7x16Change:
        data.offPeak7x16Average && data.offPeak7x16AverageYesterday
          ? data.currencySymbol +
            (
              data.offPeak7x16Average - data.offPeak7x16AverageYesterday
            ).toFixed(data.pricePrecision) +
            " " +
            data.uomSymbol
          : "-",
      abSpotNatGas: data.gasSettlePrice
        ? currencySymbol + data.gasSettlePrice.toFixed(pricePrecision)
        : "-",
      weightedAverage7x24HeatRate:
        data.gasSettlePrice && data.weightedAverage
          ? (data.weightedAverage / data.gasSettlePrice).toFixed(2)
          : "-",
      uomSymbol: data.uomSymbol,
    }));
  }

  render() {
    const { summaryData } = this.props;

    const tableData = _.orderBy(
      this.mapToViewModel(summaryData),
      "sortOrder",
      "asc"
    );

    return (
      <Table
        style={{ whiteSpace: "normal" }}
        className="table-extra-small"
        columns={this.columns}
        sortColumn={"sortOrder"}
        data={tableData}
        valueProperty="sortOrder"
      />
    );
  }
}

export default withTranslation(["marketdata"])(AesoSummaryTable);
